@import "@sundae/gov-toolkit/dist/styles.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@font-face {
    font-family: 'Roobert';
    src: url('/static/fonts/Roobert-Heavy.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('/static/fonts/Roobert-HeavyItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

// Roobert SemiBold 500
@font-face {
    font-family: 'Roobert';
    src: url('/static/fonts/Roobert-SemiBold.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('/static/fonts/Roobert-SemiBoldItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}